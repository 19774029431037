import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";

export default ({location}) => {

    return (<ThankYouPage location={location}>
            <h1>Thank you!</h1>
            <p>Your code is on it’s way to you, please check your inbox.<br/>Stop by our booth and say hi. See you at the conference!</p>
            <p>Have an amazing day!</p>
        </ThankYouPage>
    );
}